import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import ChevronArrow from '../../../images/arrow--caret-up.svg'
import ArtistPromoIcon from '../../../images/artist-icon.svg'
import ScheduleIcon from '../../../images/calendar-icon.svg'
import BreakoutIcon from '../../../images/circle-with-branching-circles-icon.svg'
import DiscoGlobeIcon from '../../../images/disco-globe-icon.svg'
import SpeakersIcon from '../../../images/lectern-icon.svg'
//import FAQIcon from '../../../images/rectangle-speech-bubbles-icon.svg'
import RecognitionIcon from '../../../images/seint-trophy-icon.svg'
import TicketIcon from '../../../images/ticket-icon.svg'

const Wrapper = styled.div`
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .headline--reunion {
    margin-bottom: 10px;
  }

  .features {
    display: grid;
    grid-gap: 20px;
  }

  article {
    display: flex;
    flex-direction: column;

    .icon {
      margin-top: auto;
    }
  }

  .icon svg {
    margin: 25px auto;
  }

  .reunion-tickets {
    .icon svg {
      width: 112px;
    }
  }

  .reunion-venue {
    .icon svg {
      width: 121px;
    }
  }

  .reunion-schedule {
    .icon svg {
      width: 87px;
    }
  }

  .reunion-breakout-sessions {
    .icon svg {
      width: 107px;
    }
  }

  .reunion-classes {
    .icon svg {
      width: 101px;
    }
  }

  .reunion-parties {
    .icon svg {
      width: 104px;
    }
  }

  .reunion-speakers {
    .icon svg {
      width: 103px;
    }
  }

  .reunion-recognition {
    .icon svg {
      width: 75px;
    }
  }

  .reunion-faq {
    .icon svg {
      width: 110px;
    }
  }

  .btn--transparent {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .link-arrow {
    width: 11px;
    margin-left: -2px;
    transform: rotate(90deg);
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    .features {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 80px 100px;
    }

    article {
      .icon {
        display: inline-flex;
        justify-content: center;
        align-items: flex-end;
        height: 138px;
        flex-shrink: 0;
      }

      .icon svg {
        margin-top: 0;
      }

      .btn--transparent {
        flex: 1;
      }
    }
  }
`

const VirtualMeetingIndex = () => {
  return (
    <Wrapper className="wrap">
      <section className="section">
        <h2 className="headline--reunion">Overview</h2>
        <p>
          Join us for our virtual meeting, LOVE, on Saturday, February 3rd, 2024 for an event all about cultivating, growing and spreading the beauty of love to create a flourishing and successful life for yourself and for those in your Artist community.
        </p>
      </section>
      <section className="section features">
        <article className="reunion-schedule">
          <Link to="/events/virtual-meeting/schedule/" className="icon">
            <ScheduleIcon />
          </Link>
          <p className="title">Agenda</p>
          <p>
            Get ready for an exciting Saturday filled with love-enriched trainings, recognition, and Seint Awards featuring your fellow Artists.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/virtual-meeting/schedule/"
          >
            View Agenda <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-classes">
          <Link to="/events/virtual-meeting/speakers/" className="icon">
            <SpeakersIcon />
          </Link>
          <p className="title">Speakers</p>
          <p>
            Learn how to nurture your love for yourself, your business, and your community with comprehensive training led by Cara, seasoned guest speakers, and your fellow Seint Artists.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/virtual-meeting/speakers/"
          >
            View Speakers <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-speakers">
          <Link to="/events/virtual-meeting/breakout-sessions/" className="icon">
            <BreakoutIcon />
          </Link>
          <p className="title">Breakout Sessions</p>
          <p>
            Learn from your fellow Seint Artists during our LOVE Breakout Sessions! Topics covered will focus on Beauty and Business tips and tricks. The sessions will be held LIVE throughout the week following LOVE. Tune in to all of them, or just a few, to cater your LOVE experience to help your business thrive.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/virtual-meeting/breakout-sessions/"
          >
            Learn More <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-speakers">
          <Link to="/events/virtual-meeting/promotion/" className="icon">
            <ArtistPromoIcon />
          </Link>
          <p className="title">LOVE Artist Sign Up Promotion</p>
          <p>
            When you genuinely love something and are passionate about it, you want to share it with others! We are offering a new Artist sign-up promotion leading up to the virtual meeting to kick off a new Artist's journey with a fresh start and perspective.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/virtual-meeting/promotion/"
          >
            Learn More <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-tickets">
          <Link to="/events/virtual-meeting/tickets/" className="icon">
            <TicketIcon />
          </Link>
          <p className="title">Tickets</p>
          <p>
            We can't wait for all of our Artists to join us for LOVE, our virtual meeting dedicated to embracing self-love, team spirit, and passion for Seint! Get ready to be inspired and put your best foot forward so you can make 2024 your best year yet. This virtual meeting allows you to join from the cozy confines of your own home, with two ticket options for you to choose from. We can't wait to have you with us!
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/virtual-meeting/tickets/"
          >
            Register Now <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-recognition">
          <Link to={`/events/virtual-meeting/parties/`} className="icon">
            <DiscoGlobeIcon />
          </Link>
          <p className="title">Leadership Virtual Party</p>
          <p>
            Let’s party together, no matter the distance! Don't let distance hinder your ability to have a great time. Come join us at our virtual party, where we'll celebrate life's moments with a purpose. It's a friendly reminder to live each day to the fullest, and to always stop and notice the love and beauty around you.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to={`/events/virtual-meeting/parties/`}
          >
            View Parties <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-recognition">
          <Link to={`/events/virtual-meeting/recognition/`} className="icon">
            <RecognitionIcon />
          </Link>
          <p className="title">The Seint Awards</p>
          <p>
            The Seint Awards will recognize exceptional artistic and personal achievements within the Seint community, and will be presented and announced during our virtual meeting, LOVE.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to={`/events/virtual-meeting/recognition/`}
          >
            Learn More <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        {/*
        <article className="reunion-faq">
          <Link to="/events/virtual-meeting/policies-and-faq/" className="icon">
            <FAQIcon />
          </Link>
          <p className="title">Policies & FAQ</p>
          <p>
            Still have questions? We’ve got you covered! Check out the Policies and FAQs section for more details about LOVE.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/virtual-meeting/policies-and-faq/"
          >
            Read Here <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        */}
      </section>
    </Wrapper>
  )
}

export default VirtualMeetingIndex
